/* unplugin-vue-components disabled */import __unplugin_components_6 from 'C:/docker/118_meijia/frontend/src/components/DropdownCalendar.vue';
import __unplugin_components_5 from 'C:/docker/118_meijia/frontend/src/components/BRow.vue';
import __unplugin_components_4 from 'C:/docker/118_meijia/frontend/src/components/form/BarCustomControl.vue';
import __unplugin_components_3 from 'C:/docker/118_meijia/frontend/src/components/BaseContentToggle.vue';
import __unplugin_components_2 from 'C:/docker/118_meijia/frontend/src/components/form/MInput.vue';
import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/form/FormItem.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/HeadShotUploader.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "col-4"
};
var _hoisted_2 = {
  class: "col-4"
};
var _hoisted_3 = {
  class: "col-4"
};
var _hoisted_4 = {
  class: "col-12"
};
var _hoisted_5 = {
  class: "col-12"
};
import { Pos, Customer, basePost } from "@/js/services/baseService";
import moment from 'moment';
export default {
  __name: 'CustomerInfoBlock',
  props: {
    code: String,
    comeFrom: String
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;

    var dropdownConfig = function () {
      if (window.innerWidth > 500) {
        return {
          position: 'right top',
          tetherOptions: {
            offset: '-20px -15px'
          }
        };
      }

      return {
        position: 'top left',
        tetherOptions: {
          offset: '-10px 0'
        }
      };
    }();

    var store = useStore();
    var userLoginID = computed(function () {
      if (store.state.auth.loginId) {
        return store.state.auth.loginId;
      }

      if (props.comeFrom === 'cust') {
        return 'Cust';
      }

      if (props.comeFrom === 'admin') {
        return 'admin';
      }

      return store.state.auth.loginId;
    });
    var cData = reactive({
      userLoginID: userLoginID,
      csid: props.code,
      custName: '',
      custGSM: '',
      // (西元yyyy/MM/dd)
      custBirthday: '',
      birthdayFormat: computed(function () {
        if (cData.custBirthday) {
          return moment(cData.custBirthday).format('YYYY.MM.DD');
        } else {
          return '';
        }
      }),
      // (M=男/F=女/X=其他/null=未定)
      sexType: '',
      custTWIDtype: 0,
      custTWIDdata: '',
      custAddress: '',
      custEmail: '',
      custMemo: ''
    });
    var idType = ref([]);
    Pos.handle('GetPhrase', {
      phraseType: 'CustTWIDtypeCode',
      beginModifyTime: 'ALL'
    }).then(function (response) {
      idType.value = response.data;
    });
    var isReady = ref(false);

    function getCsInfo() {
      Customer.get({
        mbCSID: props.code
      }).then(function (response) {
        if (response.length > 1) {
          appSwalCustomDialog.alert({
            title: appSwalCustomDialog.ALERT_COMMON_TITLE,
            content: '【資料解析錯誤，代碼(E002)】'
          });
          return;
        }

        if (response.length) {
          console.log(response[0].mbBirthday);
          cData.custName = response[0].mbCustName;
          cData.custGSM = response[0].mbGSM;
          cData.custBirthday = response[0].mbBirthday;
          cData.custTWIDtype = response[0].custTWIDtype;
          cData.custTWIDdata = response[0].custTWIDdata;
          cData.sexType = response[0].sexType;
          cData.custEmail = response[0].custEmail;
          cData.custAddress = response[0].mbAddress;
        }

        if (!isReady.value) {
          nextTick(function () {
            isReady.value = true;
          });
        }
      });
    }

    var updateCus = _.debounce(function () {
      if (cData.csId) {
        if (cData.custTWIDtype === '0' && !checkTWID(cData.custTWIDdata)) {
          appSwalCustomDialog.alert({
            title: appSwalCustomDialog.ALERT_COMMON_TITLE,
            content: '請輸入正確的台灣身分證證號'
          });
          return;
        }

        Pos.handle('CustUpdate', _objectSpread(_objectSpread({}, cData), {}, {
          custBirthday: cData.birthdayFormat
        })).then(function (response) {
          if (response.status === 200) {
            updateCustomerOrder();
          }
        });
      }
    }, 500);

    var updateCustomerOrder = function updateCustomerOrder() {
      Customer.get({
        mbCSID: props.code
      }).then(function (response) {
        if (response.length > 1) {
          appSwalCustomDialog.alert({
            title: appSwalCustomDialog.ALERT_COMMON_TITLE,
            content: '【資料解析錯誤，代碼(E002)】'
          });
          return;
        }

        if (response.length) {
          basePost('/customerNone/updateCustomerOrder', {
            customer_code: cData.csId,
            customer_birthday: cData.birthdayFormat,
            customer_name: response[0].mbCustName
          });
        }
      });
    };

    watch(function () {
      return [cData.custName, cData.custGSM, cData.custBirthday, cData.sexType, cData.custTWIDtype, cData.custAddress, cData.custEmail, cData.custMemo];
    }, function (data) {
      // 判斷是否是第一次取得資料
      if (!isReady.value) return;
      updateCus();
    }, {
      deep: true
    });
    watch(function () {
      return props.code;
    }, function (data) {
      cData.csId = props.code;
      if (!props.code) return;
      getCsInfo();
    }, {
      immediate: true
    });

    var checkTWID = function checkTWID(value) {
      var regex = new RegExp(/^[A-Za-z][12]\d{8}$/);
      if (!regex.test(value)) return false;
      var cityCode = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
      var weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
      var id = String(cityCode.indexOf(value[0].toUpperCase()) + 10) + value.slice(1);
      var weightsNum = 0;

      for (var i = 0; i < weights.length; i++) {
        weightsNum += parseInt(id[i]) * weights[i];
      }

      return weightsNum % 10 == 0;
    }; // 證號裡面的input離焦才觸發


    var blur = function blur() {
      updateCus();
    };

    function check() {
      var error = [];

      if (cData.custName === '') {
        error.push('姓名');
      }

      if (cData.custGSM === '') {
        error.push('手機');
      }

      if (cData.custTWIDtype === '') {
        error.push('證號');
      }

      if (cData.sexType === '') {
        error.push('性別');
      }

      if (cData.custBirthday === '') {
        error.push('生日');
      }

      if (error.length) {
        appSwalCustomDialog.alert({
          title: appSwalCustomDialog.ALERT_COMMON_TITLE,
          content: "\u5132\u5B58\u5931\u6557\uFF0C".concat(error.join('、'), "\u70BA\u5FC5\u586B\u6B04\u4F4D")
        });
        return false;
      }

      if (cData.custTWIDtype === '0' && !checkTWID(cData.custTWIDdata)) {
        appSwalCustomDialog.alert({
          title: appSwalCustomDialog.ALERT_COMMON_TITLE,
          content: '請輸入正確的台灣身分證證號'
        });
        return false;
      }

      return true;
    }

    expose({
      check: check
    });
    return function (_ctx, _cache) {
      var _component_HeadShotUploader = __unplugin_components_0;

      var _component_FormItem = __unplugin_components_1;

      var _component_MInput = __unplugin_components_2;

      var _component_BaseContentToggle = __unplugin_components_3;

      var _component_BarCustomControl = __unplugin_components_4;

      var _component_BRow = __unplugin_components_5;

      var _component_DropdownCalendar = __unplugin_components_6;

      var _directive_width = _resolveDirective("width");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_FormItem, {
        label: "照片",
        class: "mb-15px"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_HeadShotUploader, {
            code: __props.code
          }, null, 8, ["code"])];
        }),
        _: 1
      }), _createVNode(_component_MInput, {
        modelValue: _unref(cData).custName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(cData).custName = $event;
        }),
        label: "姓名",
        placeholder: "請輸入",
        bg: "#ECECEC",
        fz: "18px",
        class: "mb-15px",
        name: "custName",
        required: "",
        requiredString: "(必填)"
      }, null, 8, ["modelValue"]), _createVNode(_component_MInput, {
        modelValue: _unref(cData).custGSM,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _unref(cData).custGSM = $event;
        }),
        label: "手機",
        placeholder: "請輸入",
        bg: "#ECECEC",
        fz: "18px",
        class: "mb-15px",
        "input-bind": {
          inputmode: 'tel'
        },
        name: "custGSM",
        required: "",
        requiredString: "(必填)"
      }, null, 8, ["modelValue"]), _createVNode(_component_FormItem, {
        label: "證號",
        class: "mb-15px",
        required: "",
        requiredString: "(必填)"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_BRow, {
            "gutter-x": "10px"
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(idType), function (item, index) {
                return _openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "col-6"
                }, [_createVNode(_component_BarCustomControl, {
                  modelValue: _unref(cData).custTWIDtype,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
                    return _unref(cData).custTWIDtype = $event;
                  }),
                  text: item.phName,
                  radio: "",
                  name: "custTWIDtype",
                  value: item.phCode
                }, {
                  default: _withCtx(function () {
                    return [_createVNode(_component_BaseContentToggle, {
                      "model-value": _unref(cData).custTWIDtype === item.phCode
                    }, {
                      default: _withCtx(function () {
                        return [_createVNode(_component_MInput, {
                          modelValue: _unref(cData).custTWIDdata,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
                            return _unref(cData).custTWIDdata = $event;
                          }),
                          placeholder: "請輸入",
                          bg: "#fff",
                          radius: "3px",
                          fz: "16px",
                          "custom-error": _unref(cData).custTWIDtype === '0' && !checkTWID(_unref(cData).custTWIDdata) ? '身份證號格式錯誤' : '',
                          onBlur: blur
                        }, null, 8, ["modelValue", "custom-error"])];
                      }),
                      _: 2
                    }, 1032, ["model-value"])];
                  }),
                  _: 2
                }, 1032, ["modelValue", "text", "value"])]);
              }), 128))];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_FormItem, {
        label: "性別",
        required: "",
        requiredString: "(必填)"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_BRow, {
            "gutter-x": "10px",
            mb: ""
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BarCustomControl, {
                modelValue: _unref(cData).sexType,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
                  return _unref(cData).sexType = $event;
                }),
                text: "男",
                radio: "",
                name: "sexType",
                value: "M"
              }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_BarCustomControl, {
                modelValue: _unref(cData).sexType,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
                  return _unref(cData).sexType = $event;
                }),
                text: "女",
                radio: "",
                name: "sexType",
                value: "F"
              }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_BarCustomControl, {
                modelValue: _unref(cData).sexType,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
                  return _unref(cData).sexType = $event;
                }),
                text: "不選擇",
                radio: "",
                name: "sexType",
                value: "X"
              }, null, 8, ["modelValue"])])];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_DropdownCalendar, {
        modelValue: _unref(cData).custBirthday,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return _unref(cData).custBirthday = $event;
        }),
        caret: false,
        "drop-config": _unref(dropdownConfig)
      }, {
        default: _withCtx(function () {
          return [_withDirectives(_createVNode(_component_MInput, {
            label: "生日",
            placeholder: "YYYY/MM/DD",
            bg: "#ECECEC",
            class: "mb-15px",
            fz: "16px",
            readonly: "",
            "model-value": _unref(cData).birthdayFormat,
            required: "",
            requiredString: "(必填)"
          }, null, 8, ["model-value"]), [[_directive_width, 122]])];
        }),
        _: 1
      }, 8, ["modelValue", "drop-config"]), _createVNode(_component_FormItem, {
        label: "E-mail",
        class: "mb-15px"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_BRow, {
            "gutter-x": "10px"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_MInput, {
                modelValue: _unref(cData).custEmail,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
                  return _unref(cData).custEmail = $event;
                }),
                placeholder: "請輸入E-mail(選填)",
                bg: "#ECECEC",
                fz: "18px",
                name: "custEmail"
              }, null, 8, ["modelValue"])])];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_FormItem, {
        label: "地址",
        class: "mb-15px"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_BRow, {
            "gutter-x": "10px"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_5, [_createVNode(_component_MInput, {
                modelValue: _unref(cData).custAddress,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
                  return _unref(cData).custAddress = $event;
                }),
                placeholder: "請輸入地址(選填)",
                bg: "#ECECEC",
                fz: "18px",
                name: "custAddress"
              }, null, 8, ["modelValue"])])];
            }),
            _: 1
          })];
        }),
        _: 1
      })], 64);
    };
  }
};